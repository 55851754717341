.login_route {
    max-width: 33rem;
    margin: 0 auto;
    padding: var(--nds-l-300);
}

.login_route__form {
    margin-top: var(--nds-l-400);
    border: 1px solid var(--nds-color-core_grey_200);
    padding: var(--nds-l-200);
    border-radius: var(--nds-l-utilities_50);
    position: relative;
}

.login_route__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}