.input_multi_select_control {
    position: relative;
}

.input_multi_select_control__select { display: none; }

.input_multi_select_control__label {
    font: var(--nc-t-body_medium);
    margin-bottom: var(--nds-l-utilities_50);
}
.input_multi_select_control__input {
    border: 1px solid #e6e8ea;
    box-shadow: inset 0px 0px 2px 0px #0e162529;
    position: relative;
}
.input_multi_select_control__input--active {
    border: 1px solid var(--nds-color-semantic_primary_600);
}
.input_multi_select_control__input__toggle {
    text-align: left;
    padding: var(--nds-l-200);
    width: 100%;
    height: 100%;
    display: block;
    min-height: var(--nds-l-600);
    background: transparent;
    border: none;
    cursor: pointer;
}
.input_multi_select_control__input__filler_values,
.input_multi_select_control__input__value_list {
    position: absolute;
    top: var(--nds-l-100);
    left: var(--nds-l-100);
    height: calc(100% - var(--nds-l-200));
    display: flex;
    flex-wrap: wrap;
    gap: var(--nds-l-100);
    pointer-events: none;
}
.input_multi_select_control__input__filler_values {
    position: relative;
    visibility: hidden;
}
.input_multi_select_control__input__value_list__item {
    pointer-events: all;
}
.input_multi_select_control__dropdown {
    position: absolute;
    top: calc(100% + var(--nds-l-100));
    width: 100%;
    z-index: 2000;
    border-radius: var(--nds-l-utilities_50);
    box-shadow: var(--nds-box-shadow-drop_stack_2);
    border: 1px solid #e6e8ea;
    display: flex;
    flex-direction: column;
    max-height: 14.5rem;
    overflow-y: auto;
    display: none;
    background: var(--nds-color-core_white);
}
.input_multi_select_control__dropdown--open {
    display: flex;
}
.input_multi_select_control__option {
    padding: var(--nds-l-100);
    border: none;
    text-align: left;
    background-color: transparent;
    font: var(--nc-t-sub_text_light);
    cursor: pointer;
}
.input_multi_select_control__option--selected {
    background-color: var(--nds-color-semantic_primary_50);
}
.input_multi_select_control__option:hover,
.input_multi_select_control__option--selected:hover {
    background-color: var(--nds-color-semantic_primary_100);
}