.MuiTableRow-root .actionButton {
  visibility: hidden;
}

.MuiTableRow-root:hover .actionButton {
  visibility: visible;
}

.MuiTableHead-root.table-head {
  background-color: rgba(0, 0, 0, 0.1);
}