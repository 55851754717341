.config_visual_editor_header {
    background: var(--nds-color-core_white);
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: var(--nds-box-shadow-drop_stack_2);
    z-index: 1000;
}

.config_visual_editor__title_row {
    padding: var(--nds-l-100) var(--nds-l-200);
}

.config_visual_editor_header__title {
    white-space: pre;
    text-overflow: ellipsis;
    line-clamp: 1;
    --webkit-line-clamp: 1;
    max-width: 4rem;
    overflow: hidden;
}

.config_visual_editor_header__view_toggle--hidden,
.config_visual_editor_header__title--hidden { display: none; }
.config_visual_editor_header__search_grp--wide { flex: 1; }
@media screen and (min-width: 33rem) {
    .config_visual_editor_header__title {
        max-width: 100%;
    }

    .config_visual_editor_header__view_toggle--hidden,
    .config_visual_editor_header__title--hidden { display: block; }
    .config_visual_editor_header__search_grp--wide { flex: unset; }
}

.config_visual_editor__tool_row {
    border-top: 1px solid var(--nds-color-core_grey_100);
    padding: var(--nds-l-100) var(--nds-l-200);
}

.config_visual_editor_header__toolbar {
    display: flex;
    padding: var(--nds-l-100);
    background-color: var(--nds-color-core_white);
    border-bottom: 1px solid var(--nds-color-core_grey_100);
    align-items: center;
    justify-content: space-between;
}
.config_visual_editor_header__toolbar__menu {
    background: var(--nds-color-core_grey_50);
    border-color: var(--nds-color-core_grey_50);
    border-radius: var(--nds-l-utilities_50);
    border-style: solid;
    border-width: 1px;
    color: var(--nds-color-core_grey_900);
    display: inline-block;
    flex-basis: content;
    flex: 0 0 content;
    flex: 0;
    font: var(--nc-t-body_regular);
    margin: 0;
    transition: background-color .25s, border-color .25s, color .25s;
}
.config_visual_editor_header__toolbar__menu--open {
    background: var(--nds-color-core_grey_400);
    border-color: var(--nds-color-core_grey_400);
}
.config_visual_editor_header__toolbar__menu__inner {
    cursor: pointer;
    padding: var(--nds-l-utilities_25) var(--nds-l-utilities_75);
    white-space: pre;
}
.config_visual_editor_header__toolbar__menu_dropdown { white-space: pre; }

.config_visual_editor_header__search_field { display: none; }
.config_visual_editor_header__search_field--searching {
    display: block;
    width: 100%;
}
@media screen and (min-width: 33rem) {
    .config_visual_editor_header__search_btn { display: none; }
    .config_visual_editor_header__search_field { display: block; }
}
@media screen and (min-width: 72rem) {
    .config_visual_editor_header__search_field { min-width: 24rem; }
}
