.config_visual_editor_resource_modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 98.25px);
    height: 100%;
}

.config_visual_editor_resource_modal__header {
    flex: 0;
    padding: var(--nds-l-utilities_225);
    display: flex;
    gap: 1rem;
    align-items: center;
}

.config_visual_editor_resource_modal__body {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    padding: var(--nds-l-100) var(--nds-l-utilities_225);
}

.config_visual_editor_resource_modal__footer {
    flex: 0;
}

.config_visual_editor_resource_modal__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.config_visual_editor_resource_modal__item {
    flex: 1 0 calc(33% - 1rem);
    max-width: calc(33% - 1rem);
    box-shadow: var(--nds-box-shadow-drop_stack_1);
    padding: var(--nds-l-200);
}

.config_visual_editor_resource_modal__code textarea,
.config_visual_editor_resource_modal__code .ncinputtext__control_row__control_container {
    height: calc(100vh - 270px);
    border-radius: 0 !important;
}

.config_visual_editor_resource_modal__unsaved_dismiss {
    color: var(--nds-color-semantic_warning_500);
}