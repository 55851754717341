.config_visual_editor_drawer {
    border-right: 1px solid var(--nds-color-core_grey_200);
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--nds-color-core_grey_50);
}

.config_visual_editor_drawer__content {
    overflow-y: auto;
    height: 100%;
    padding: var(--nds-l-200) 0;
}

.config_visual_editor_drawer__title {
    font: var(--nc-t-body_medium);
    color: var(--nds-color-core_grey_700);
    padding-bottom: var(--nds-l-100);
    margin: 0 var(--nds-l-200);
    margin-bottom: var(--nds-l-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--nds-color-core_grey_100);
}
.config_visual_editor_drawer__list_item {
    padding: var(--nds-l-utilities_50) var(--nds-l-200);
    font: var(--nc-t-sub_text_light);
    color: var(--nds-color-core_grey_800);
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
}
.config_visual_editor_drawer__list_item--selected,
.config_visual_editor_drawer__list_item:hover {
    background-color: var(--nds-color-semantic_primary_300);
    color: var(--nds-color-core_white);
}