.config_visual_editor_subpage_header {
    background: var(--nds-color-core_white);
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: var(--nds-box-shadow-drop_stack_2);
    z-index: 1000;
}

.config_visual_editor_subpage_header__title {
    white-space: pre;
    text-overflow: ellipsis;
    line-clamp: 1;
    --webkit-line-clamp: 1;
    max-width: 4rem;
    overflow: hidden;
}

@media screen and (min-width: 33rem) {
    .config_visual_editor_subpage_header__title {
        max-width: 100%;
    }
}
