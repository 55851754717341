.nav_tool_picker__links {
    list-style-type: none;
}

.nav_tool_picker__links__link {
    display: block;
    margin-top: var(--nds-l-200);
}
.nav_tool_picker__links__link__text {
    font: var(--nc-t-body_medium);
    color: var(--nds-color-semantic_primary_600);
    text-decoration: none;
}
.nav_tool_picker__links__link__text--active { color: var(--nds-color-core_black); }
.nav_tool_picker__links__link__text:hover {
    color: var(--nds-color-core_black);
    text-decoration: underline;
}