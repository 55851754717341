.config_visual_editor_module_modal {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
}

.config_visual_editor_module_modal__header {
    flex: 0;
    padding: var(--nds-l-utilities_225);
    display: flex;
    gap: 1rem;
    align-items: center;
}

.config_visual_editor_module_modal__body {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    padding: var(--nds-l-utilities_225);
}

.config_visual_editor_module_modal__footer {
    flex: 0;
}

.config_visual_editor_module_modal__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.config_visual_editor_module_modal__item {
    flex: 1 0 calc(33% - 1rem);
    max-width: calc(33% - 1rem);
    box-shadow: var(--nds-box-shadow-drop_stack_1);
    padding: var(--nds-l-200);
}
