.config_visual_editor_plan_error {
    background: var(--nds-color-semantic_error_50);
    border-radius: var(--nds-l-utilities_50);
    padding: var(--nds-l-100);
}

.config_visual_editor_plan_error__description {
    margin-top: var(--nds-l-utilities_50);
    font: var(--nc-t-body_regular);
    color: var(--nds-color-semantic_error_800);
}
.config_visual_editor_plan_error__source_list_title {
    font: var(--nc-t-info_text_medium);
    color: var(--nds-color-semantic_error_900);
    margin-top: var(--nds-l-100);
}