.checklist_control .checklist_control {
    margin-left: var(--nds-l-100);
    border-left: 1px solid var(--nds-color-core_grey_300);
    padding-left: var(--nds-l-100);
}
.checklist_control .checklist_control--bump_right {
    padding-left: 1.75rem;
}

.checklist_control__toggle {
    padding: 0;
    border: none;
    background: transparent;
    font: var(--nc-t-sub_text_regular);
    cursor: pointer;
    color: var(--nds-color-semantic_primary_600);
    position: relative;
    top: .25rem;
}

.checklist_control__checkbox {
    background: transparent;
    border: none;
    padding: var(--nds-l-utilities_50);
    font: var(--nc-t-body_light);
}
.checklist_control__checkbox__icon {
    color: var(--nds-color-core_grey_700);
}
.checklist_control__checkbox__icon--checked {
    color: var(--nds-color-semantic_primary_600); 
}

.checklist_control__children {
    display: block;
}
.checklist_control__children--collapsed {
    display: none;
}
