.config_visual_editor_objects_menu {
    border-left: 1px solid var(--nds-color-core_grey_200);
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--nds-color-core_grey_50);
}


.config_visual_editor_objects_menu__header {
    flex: 0;
    padding: var(--nds-l-200);
    border-bottom: 1px solid var(--nds-color-core_grey_200);
}

.config_visual_editor_objects_menu__body {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    padding: var(--nds-l-200);
}

.config_visual_editor_objects_menu__item {
    background: transparent;
    padding: var(--nds-l-200);
    border: 1px solid var(--nds-color-core_grey_100);
    background: var(--nds-color-core_white);
}
.config_visual_editor_objects_menu__item + .config_visual_editor_objects_menu__item {
    margin-top: var(--nds-l-200);
}

.config_visual_editor_objects_menu__item__index {
    background-color: var(--nds-color-core_grey_700);
    border-radius: 200px;
    padding: 0 var(--nds-l-utilities_50);
    color: var(--nds-color-core_white);
    font: var(--nc-t-info_text_light);
    margin-right: var(--nds-l-utilities_50);
}
.config_visual_editor_objects_menu__item__key { word-break: break-all; }

.config_visual_editor_objects_menu__action {
    padding: var(--nds-l-200);
    border-top: 1px solid var(--nds-color-core_grey_100);
}
