.entities_id_route {
    display: grid;
    grid-template-areas:
        "header"
        "workspace";
    grid-template-rows: auto 1fr;
    position: relative;
}
.entities_id_route__header {
    position: sticky;
    top: 0;
    grid-area: header;
    z-index: 2000;
}

.entities_id_route__workspace { grid-area: workspace; }

.entities_id_route--drawer .entities_id_route__workspace { display: none; }
.entities_id_route--drawer {
    grid-template-areas:
        "header"
        "drawer";
}
.entities_id_route--tray .entities_id_route__workspace { display: none; }
.entities_id_route--tray {
    grid-template-areas:
        "header"
        "tray";
}
.entities_id_route__drawer,
.entities_id_route__tray {
    grid-area: tray;
    overflow-y: auto;
    height: calc(100vh - 98.25px);
    position: sticky;
    top: 98.25px;
}
.entities_id_route__drawer { grid-area: drawer; }
@media screen and (min-width: 33rem) {
    .entities_id_route--drawer .entities_id_route__workspace { display: block; }
    .entities_id_route--tray .entities_id_route__workspace { display: block; }
    .entities_id_route--drawer {
        grid-template-areas:
            "header header"
            "drawer workspace";
        grid-template-columns: auto 1fr;
    }
    .entities_id_route--tray {
        grid-template-areas:
            "header header"
            "workspace tray";
        grid-template-columns: 1fr auto;
    }
    .entities_id_route--tray.entities_id_route--drawer {
        grid-template-areas:
            "header header header"
            "drawer workspace tray";
        grid-template-columns: auto 1fr auto;
    }
    .entities_id_route__drawer {
        width: 256px;
    }
    .entities_id_route__tray {
        width: 375px;
    }
}
