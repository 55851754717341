.config_action + .config_action {
    margin-top: var(--nds-l-200);
}

.config_action__title {
    font: var(--nc-t-sub_text_medium);
    margin-bottom: var(--nds-l-utilities_50);
}

.config_action__title--success { color: var(--nds-color-semantic_success_600); }
.config_action__title--warning { color: var(--nds-color-semantic_warning_600); }
.config_action__title--error { color: var(--nds-color-semantic_error_600); }

.config_action__details {
    position: relative;
    padding: 0;
}
.config_action__details__contents {
    padding: var(--nds-l-200);
    max-height: 24rem;
    overflow-y: auto;
}
.config_action__copy_button {
    position: absolute!important;
    top: var(--nds-l-200);
    right: var(--nds-l-400);
}
