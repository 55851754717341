.entities_id_plan_route {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.entities_id_plan_route__body {
    padding: var(--nds-l-200);
    flex: 1;
    overflow-y: auto;
}

.entities_id_plan_route__footer {
    border-top: 1px solid var(--nds-color-core_grey_100);
    padding: var(--nds-l-utilities_225);
}
