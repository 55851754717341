.entities_route__add_entity {
    background: var(--nds-color-semantic_primary_50);
    color: var(--nds-color-semantic_primary_400);
    padding: var(--nds-l-100);
    text-decoration: none;
    border-radius: var(--nds-l-utilities_50);
    font: var(--nc-t-body_medium);
    margin-left: var(--nds-l-200);
    transition: background-color 125ms, color 125ms;
}
.entities_route__add_entity:hover {
    background: var(--nds-color-semantic_primary_400);
    color: var(--nds-color-core_white);
}
.entities_route__add_entity:active {
    background: var(--nds-color-semantic_primary_900);
    color: var(--nds-color-core_white);
}

.entities_route__entity {
    padding: 0;
    margin: 0;
}
.entities_route__entity:hover {
    background: var(--nds-color-semantic_primary_50)!important;
}
.entities_route__entity__primary,
.entities_route__entity__detail {
    height: 100%;
    text-decoration: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    padding: var(--nds-l-utilities_150) var(--nds-l-100);
}
.entities_route__entity__primary {
    color: var(--nds-color-core_grey_900);
    font: var(--nc-t-body_regular);
}
.entities_route__entity__detail {
    color: var(--nds-color-core_grey_500);
    font: var(--nc-t-sub_text_light);
}
