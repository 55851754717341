.config_visual_editor_section {
    border: 1px solid var(--nds-color-core_grey_200);
    border-radius: var(--nds-l-utilities_50);
    box-shadow: var(--nds-box-shadow-drop_core_1);
    background-color: var(--nds-color-core_white);
}

.config_visual_editor_section__header {
    padding: var(--nds-l-100) var(--nds-l-200);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--nds-color-core_grey_100);
}

.config_visual_editor_section__drawer {
    padding: var(--nds-l-100) var(--nds-l-200);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.config_visual_editor_section__drawer--closed {
    height: 0;
    padding: 0;
}

.config_visual_editor_section__node {
    --node_width: calc(375px - 3rem);
    flex: 1 0 var(--node_width);
    width: var(--node_width);
    max-width: var(--node_width);
}