.navbar {
    background: var(--nds-color-core_white);
    padding: var(--nds-l-200);
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: var(--nds-box-shadow-drop_stack_2);
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navbar__links {
    list-style-type: none;
}

.navbar__links__link {
    display: inline-block;
    margin-right: var(--nds-l-200);
}
.navbar__links__link__text {
    font: var(--nc-t-body_medium);
    color: var(--nds-color-semantic_primary_600);
    text-decoration: none;
}
.navbar__links__link__text--active { color: var(--nds-color-core_black); }
.navbar__links__link__text:hover {
    color: var(--nds-color-core_black);
    text-decoration: underline;
}