.config_visual_editor_resource {
    background: var(--nds-color-core_white);
    border: 1px solid var(--nds-color-core_grey_200);
    border-radius: var(--nds-l-utilities_25);
    padding: var(--nds-l-100);
    width: 100%;
    height: 100%;
}

.config_visual_editor_resource--unsaved {
    color: var(--nds-color-semantic_warning_500);
}